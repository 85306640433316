import { createApp } from 'vue'
import App from './App.vue'
import { createStore } from 'vuex'

// Create a new store instance.
export const store = createStore({
  state () {
    return {
      //baseURL: 'https://tailormade-backend-9c069a33f8b5.herokuapp.com/',
      //baseURL: ' http://localhost:3001/',
      baseURL: 'https://www.json.freston.io/',
      
      shop: 
      {
        "name": "Al Lauz Tailoring",
        "username": "allauz",
        "id": 1,
        "uid": "ywHSdgcep8LrfXTJ",
        "dresses": [
          {
            "name": "Kandura",
            "uid": "H7gnU8uGy3WSjK01"
          }
        ]
      },
      // {
      //   "name": "Stitch Well Tailoring",
      //   "username": "stitchwell",
      //   "id": 2,
      //   "uid": "Wf2vrf1T82qVgFag"
      // },
      user: {
          loggedIn: true,
          data: {}
      },
    }
  },
  mutations: {
    increment (state) {
      state.count++
    }
  }
})

const app = createApp({ App })

// Install the store instance as a plugin
app.use(store)

