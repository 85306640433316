<template>
      <!-- Container -->
      <div class="container">
        <!-- Sign In Popup -->
			<div id="sign-in-dialog">

				<div class="small-dialog-header">
					<h3>Sign In</h3>
				</div>
			</div>

        <!-- Sign In Popup / End -->
      </div>
      <!-- Content / End -->
</template>

<script>
// import axios from "axios";

// const baseURL = "http://localhost:3001/";

export default {
  name: "Login",
};
</script>

<style scoped>

</style>