<template>
    <!-- Header Container
      ================================================== -->
      <div id="header-container">

        <!-- Header -->
        <div id="header">
          <div class="container">
            
            <!-- Left Side Content -->
            <div class="left-side">
              
              <!-- Logo -->
              <div id="logo">
                <a href="/">
                  <img src="../assets/images/al-lauz-logo.svg" alt="">
                  <!-- {{shop.name}} -->
                </a>
              </div> 
            </div>
            <!-- Left Side Content / End -->

            <!-- Right Side Content / End -->
            <div class="right-side">
              <!-- Header Widget -->
              <div class="header-widget">
                
                <!-- User Menu -->
                <div class="user-menu">
                  <!-- <div class="user-name" @click="signOut()"><span><img src="./assets/images/placeholder.png" alt=""></span>Hi, {{user.data.displayName}}</div> -->
                  <div class="user-name" @click="signOut()">
                    <!-- <span><img src="../assets/images/placeholder.png" alt=""></span> -->
                    <i class="sl sl-icon-power"></i> Logout
                    </div>
                  <!-- <ul>
                    <li><a @click="signOut()"><i class="sl sl-icon-power"></i> Logout</a></li>
                  </ul> -->
                </div>
                <router-link to="/addcontact" class="button border with-icon">New Order <i class="sl sl-icon-plus"></i></router-link>
              </div>
              <!-- Header Widget / End -->
            </div>
            <!-- Right Side Content / End -->
          </div>
        </div>
        <!-- Header / End -->

      </div>
</template>

<script>
// import axios from "axios";

// const baseURL = "http://localhost:3001/";

export default {
  name: "HeaderBar",
  props: {
     customer: Object
  },
//   data() {
//     return {
//       customers: []
//     };
//   },
//   async created() {
//     try {
//         const res = await axios.get(baseURL+'customers?parentId=0&connectedShops_like=allauz');
//         this.customers = res.data;
//     } catch (e) {
//       console.error(e);
//     }
//   },
  /*
    methods: {
        async doneCustomer(id) {
            try {
                await axios.patch(`${baseURL}customers/${id}`, {
                    done: true
                });

                this.customers = this.customers.map(customer => {
                    if (customer.id === id) {
                        customer.done = true;
                    }

                    return customer;
                });
            } catch (e) {
                console.error(e);
            }
        },
        async addcustomer() {
            try {
                const res = await axios.post(baseURL+'customers', { name: this.customerName });

                this.customers = [...this.customers, res.data];

                this.customerName = "";
            } catch (e) {
                console.error(e);
            }
        }
    }
    */
};
</script>

<style scoped>

</style>