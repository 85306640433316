import { createApp } from "vue";
import App from "./App.vue";
import * as VueRouter from "vue-router";

const routes = [
  {
    path: "/",
    name: "home-page",
    component: () => import("@/views/HomePage.vue"),
  },
  {
    path: "/orderdetails/:orderUid?",
    name: "order-details",
    props: true,
    component: () => import("@/views/DetailsPage.vue"),
  },
  // {
  //   path: '/addnew',
  //   name: 'add-new-order',
  //   component: () => import("@/components/AddNewOrder.vue")
  // },
  {
    path: "/addcontact",
    name: "add-new-contact",
    component: () => import("@/views/AddContactPage.vue"),
  },
  // {
  //   path: "/addmeasurement/:customerUid/:dressUid/:measurementUid/:dependentUid?",
  //   name: "add-measurement",
  //   props: true,
  //   component: () => import("@/views/AddMeasurementPage.vue"),
  // },
  {
    path: "/addmeasurement/:measurementUid",
    name: "add-measurement",
    props: true,
    component: () => import("@/views/AddMeasurementPage.vue"),
  },
  {
    path: "/addorder/:orderUid",
    name: "add-order",
    props: true,
    component: () => import("@/views/AddOrderPage.vue"),
  },
  // {
  //   path: "/addorder/:customerUid/:dressUid/:measurementUid/:dependentUid?",
  //   name: "add-order",
  //   props: true,
  //   component: () => import("@/views/AddOrderPage.vue"),
  // },
  // { path: '/orderdetails/:id?',
  //   name: 'order-details',
  //   props: true,
  //   component: () => import("@/components/OrderDetails.vue")
  // },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = VueRouter.createRouter({
  routes, // short for `routes: routes`
  //mode: 'history',
  history: VueRouter.createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
// const router = VueRouter.createRouter({
//   // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
//   history: VueRouter.createWebHashHistory(),
//   routes, // short for `routes: routes`
// })

const app = createApp(App);
app.use(router);
app.mount("#app");
