<template>
  <div v-if="authenticated" id="wrapper">
    <header-bar/>
    <div class="clearfix"></div>
    <div class="container">
      <router-view></router-view>
    <!-- Container / End -->

    <!-- Content -->
    </div>
  </div>
  <div v-else id="wrapper">
    <login/>
  </div>
</template>

<script>
import HeaderBar from "./components/HeaderBar.vue";
import Login from "./components/LoginDemo.vue";

import { store } from "./store";

export default {
  name: "App",
  components: {
    HeaderBar,
    Login
  },
  data() {
    return {
      activeTab: 'login',
      user: {
        loggedIn: true,
        data: {}
      },
    }
  },
  computed: {
    authenticated(){
      return this.user.loggedIn
    },
    shop() {
      return store.state.shop;
    }
  },
  mounted: function() {
      if (store.state.user) {
        this.user = store.state.user
        //store.dispatch("bindOrders", this.user.data.displayName);
      }
      else {
        this.user.loggedIn = false;
        this.user.data = {};
      }
  },
};
</script>

<style>
  @import '~@/assets/css/style.css'; 
  @import '~@/assets/css/main-color.css';
  @import '~@/assets/css/dokan.css'; 
</style>
